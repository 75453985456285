import React, { useState, useContext, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import NumberFormat from "react-number-format";

import { BsCheckCircleFill } from "react-icons/bs";

import { APIDOSS, APISV } from "../configs/API";
import { ProtectionList } from "../configs/protectionList";
import { AppContext } from "../configs/AppContext";

import ComboBox from "./ComboBox";
import SNInputGroup from "./SNInputGroup";
import SNInputPWP from "./SNInputPWP";
import SNInputChild from "./SNInputChild";

const SNInput = ({ item, index, index2, filterSN, setFilterSN }) => {
  const [state, dispatch] = useContext(AppContext);
  const [serialNumber, setSerialNumber] = useState("");
  const [snList, setSnList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [protectionMoveable, setProtectionMoveable] = useState(null);
  const [protectionExtended, setProtectionExtended] = useState(null);
  const [protectionList, setProtectionList] = useState(null);

  const { activeCart } = state;

  const [verified, setVerified] = useState(false);

  const getProtectionList = async () => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const res = await APIDOSS.get("protection");
      setProtectionList(res.data.data);

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      alert(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const payload = {
        serial_number: serialNumber,
      };
      const res = await APISV.post(
        `s-cart/${item.cart_id}/item/${item.id}/sn`,
        payload
      );

      if (res.data.error == 0) {
        setVerified(true);

        setFilterSN((oldArray) => [...oldArray, serialNumber]);

        const resCart = await APISV.get(`cart/${activeCart.order_id}`);
        if (resCart.data.error == 0) {
          dispatch({
            type: "SET_ACTIVE_CART",
            payload: resCart.data.data,
          });
        } else {
          dispatch({
            type: "DELETE_ACTIVE_CART",
          });
          dispatch({
            type: "DELETE_ACTIVE_TAX",
          });
        }
      } else {
        alert(res.data.description);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert(error);
    }
  };

  const getSerialNumber = async () => {
    try {
      const res = await APISV.get(`s-cart/${item.cart_id}/item/${item.id}/sn`);

      if (res.data.error == 0) {
        let filtered = res.data.data.serial_numbers.filter(
          (item) => !filterSN.includes(item)
        );
        setSnList(filtered);
      } else {
        alert(res.data.description);
      }
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  const applyDossProtection = async (protect) => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      let isProtected =
        item.applied_protection &&
        item.applied_protection.find((prt) => prt.plan_id == protect.plan_id);

      let res;

      if (isProtected) {
        res = await APISV.delete(`s-cart/${item.cart_id}/warranty/${item.id}`);
      } else {
        const payload = {
          item_id: item.id,
          plan_id: protect.plan_id,
        };
        res = await APISV.post(`s-cart/${item.cart_id}/warranty`, payload);
      }

      if (res.data.error == 0) {
        getLatestCartInfo();
      } else {
        alert(res.data.description);
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      alert(error);
    }
  };

  const deleteProtection = async () => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const res = await APISV.delete(
        `s-cart/${item.cart_id}/warranty/${item.id}`
      );

      if (res.data.error == 0) {
        getLatestCartInfo();
      } else {
        alert(res.data.description);
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      alert(error);
    }
  };

  const getDefaultProtection = () => {
    const moveableProtect = ProtectionList.filter(
      (protect) =>
        protect.plan_title === "DOSS Protection Moveable All Risk 1 Tahun" &&
        item &&
        item.item_price >= protect.min_price &&
        item.item_price <= protect.max_price
    );

    if (moveableProtect) {
      setProtectionMoveable(moveableProtect[0]);
    }

    const extendedProtect = ProtectionList.filter(
      (protect) =>
        protect.plan_title ===
          "DOSS Protection Extended Warranty 1 + 1 Tahun" &&
        item &&
        item.item_price >= protect.min_price &&
        item.item_price <= protect.max_price
    );

    if (extendedProtect) {
      setProtectionExtended(extendedProtect[0]);
    }
  };

  const getLatestCartInfo = async () => {
    try {
      const resCart = await APISV.get(`cart/${state.activeCart.order_id}`);
      if (resCart.data.error == 0) {
        dispatch({
          type: "SET_ACTIVE_CART",
          payload: resCart.data.data,
        });
      } else {
        dispatch({
          type: "DELETE_ACTIVE_CART",
        });
        dispatch({
          type: "DELETE_ACTIVE_TAX",
        });
      }
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  useEffect(() => {
    // getDefaultProtection();
    // getProtectionList();

    if (item.product && item.serial_numbers[0] !== "") {
      setSerialNumber(item.serial_numbers[0]);
      setVerified(true);
    } else if (!item.product && item.serial_numbers.length !== 0) {
      setSerialNumber(item.serial_numbers[0]);
      setVerified(true);
    }
  }, []);

  return (
    <>
      <div className="d-flex mt-2">
        <div className="sn-input-protection me-2">Item ID: </div>
        <div className="fw-bold">{index}</div>
      </div>

      {!item.group_items && (
        <form onSubmit={handleSubmit}>
          <div className="row d-flex  mb-3">
            <div className="col-md-2 ">
              <div className="sn-input-protection">Product ID</div>
              <div className="fw-bold">
                #{item.product_id ? item.product_id : item.id}
              </div>
            </div>
            <div className="col-md-10 d-flex flex-column">
              <div className="row mb-3">
                <div className="col fw-bold">
                  {item.product ? item.product.name : item.name}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="d-flex">
                    <ComboBox
                      getOptions={getSerialNumber}
                      snList={snList}
                      onChange={(e) => setSerialNumber(e)}
                      disabled={verified}
                      defaultValue={item.serial_numbers[0]}
                    />
                    <button
                      type="submit"
                      className={`btn ${
                        verified ? "btn-success" : "btn-primary"
                      } sn-button`}
                      disabled={verified}
                    >
                      {loading && (
                        <Spinner
                          animation="border"
                          style={{
                            color: "#ffff",
                            width: "25px",
                            height: "25px",
                          }}
                        />
                      )}
                      {!loading && !verified && "Submit"}
                      {!loading && verified && (
                        <BsCheckCircleFill color="#FFFF" size={25} />
                      )}
                    </button>
                  </div>
                  {/* <div className="position-relative mb-2">
                  
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Masukan Serial Number Produk"
                    name="serial_number"
                    value={serialNumber}
                    onChange={(e) => setSerialNumber(e.target.value)}
                    onFocus={() => getSerialNumber()}
                  />
                  <button
                    style={{ width: "100px" }}
                    type="submit"
                    className={`btn ${
                      verified ? "btn-success" : "btn-primary"
                    } sn-sidebar-input`}
                    // onClick={() => setVerified(true)}
                    disabled={verified}
                  >
                    {!loading && verified && (
                      <BsCheckCircleFill color="#FFFF" size={25} />
                    )}

                    {!loading && !verified && "Submit"}

                    {loading && (
                      <Spinner
                        animation="border"
                        style={{
                          color: "#ffff",
                          width: "25px",
                          height: "25px",
                        }}
                      />
                    )}
                  </button>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </form>
      )}

      {/* CHILD ITEMS */}
      {item.child_items &&
        item.child_items.map((child) => (
          <SNInputChild
            key={child.id}
            item={child}
            filterSN={filterSN}
            setFilterSN={(e) => setFilterSN(e)}
            cartId={item.cart_id}
            itemId={item.id}
          />
        ))}

      {/* GROUP ITEMS */}
      {item.group_items &&
        item.group_items.map((group) => (
          <SNInputGroup
            key={group.id}
            item={group}
            filterSN={filterSN}
            setFilterSN={(e) => setFilterSN(e)}
            cartId={item.cart_id}
            itemId={item.id}
          />
        ))}

      {/* DOSS PROTECTION */}
      {/* {item.product && item.product.protection_applicable && (
        <div className="mt-2">
          <div className="sn-input-protection mb-2">Apply DOSS Protection?</div>
          {protectionList &&
            protectionList.map((protect) => (
              <div key={protect.id}>
                <input
                  type="checkbox"
                  id={`protect` + protect.id}
                  onClick={() => applyDossProtection(protect)}
                  disabled={!protectionMoveable}
                  checked={
                    item.applied_protection &&
                    item.applied_protection.length > 0 &&
                    item.applied_protection.find(
                      (prt) => prt.plan_id === protect.plan_id
                    )
                  }
                />{" "}
                <label
                  htmlFor={`protect` + protect.id}
                  className="cursor-pointer"
                >
                  {protect.name} -{" "}
                  <NumberFormat
                    value={protect.price}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix={"IDR "}
                  />
                </label>
              </div>
            ))}
        </div>
      )}

      {item.applied_protection && item.applied_protection.length > 0 && (
        <button
          className="btn btn-secondary mt-2"
          onClick={() => deleteProtection()}
        >
          Hapus Protection
        </button>
      )} */}

      {/* <hr /> */}

      {/* PWP ITEMS */}
      {item.free_items && <div className="fw-bold">PWP Products</div>}
      {item.free_items &&
        item.free_items.map((free) => (
          <SNInputPWP
            key={free.id}
            item={free}
            index={`#${free.id}`}
            index2={index2}
            filterSN={filterSN}
            setFilterSN={(e) => setFilterSN(e)}
          />
        ))}

      <hr />
    </>
  );
};

export default SNInput;
