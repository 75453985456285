import React, { useState } from "react";
import { HiOutlineTrash } from "react-icons/hi";

import ModalConfirmDelete from "./ModalConfirmDelete";

const TitleSidebar = ({ activeCart }) => {
  const [modalConfirm, setModalConfirm] = useState(false);
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const dbList = JSON.parse(localStorage.getItem("db_list"));
  const branch = JSON.parse(localStorage.getItem("branch"));
  const warehouse = JSON.parse(localStorage.getItem("warehouse"));

  return (
    <>
      <ModalConfirmDelete
        show={modalConfirm}
        handleClose={() => setModalConfirm(false)}
        activeCart={activeCart}
      />

      <div className="fw-bold">Checkout Cart</div>
      <div className="cart-sidebar-subtitle">
        Database : {dbList.alias} [{dbList.id}] | Cabang: {branch.name} [
        {branch.id}] | Warehouse: {warehouse.name} [{warehouse.id}]
      </div>
      <div className="cart-sidebar-subtitle">
        Officer Name : {user && user.name}
      </div>
      <div className="cart-sidebar-subtitle">
        Cart No. {activeCart.id} | Order ID : {activeCart.order_id} |
        <span
          className="ms-1 delete-cart"
          onClick={() => setModalConfirm(true)}
        >
          <HiOutlineTrash className="me-1" />
          Delete Cart
        </span>
      </div>
      <div className="cart-sidebar-subtitle">
        Customer : {activeCart.user.fullname} | {activeCart.user.email} |{" "}
        {activeCart.user.phone}
      </div>
    </>
  );
};

export default TitleSidebar;
