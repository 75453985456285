import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

export default function ComboBox({
  getOptions,
  snList,
  onChange,
  disabled,
  defaultValue,
}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(async () => {
    if (open && options.length == 0) {
      setLoading(true);
      await getOptions();
      setLoading(false);
    }
  }, [open]);

  React.useEffect(() => {
    snList && setOptions(snList);
  }, [snList]);

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      disabled={disabled}
      autoSelect
      onSelect={(e) => onChange(e.target.value)}
      size="medium"
      options={options}
      loading={loading}
      defaultValue={defaultValue}
      renderInput={(params) => (
        <TextField
          {...params}
          label=""
          placeholder="Input Serial Number"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
