import React, { createContext, useReducer } from "react";

export const AppContext = createContext();

const initialState = {
  isLogin: undefined,
  user: null,
  loading: true,
  dim: false,
  collapse: false,
  toast: {
    isOpen: false,
    message: "",
  },
  activeCustomer: undefined,
  activeCart: undefined,
  activeTax: undefined,
  cartShow: false,
  snShow: false,
  taxShow: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_LOGIN":
      return {
        ...state,
        isLogin: true,
      };
    case "SET_DIM":
      return {
        ...state,
        dim: action.payload,
      };
    case "SET_TOAST":
      return {
        ...state,
        toast: action.payload,
      };
    case "SET_COLLAPSE":
      return {
        ...state,
        collapse: action.payload,
      };
    case "SET_ACTIVE_CUSTOMER":
      localStorage.setItem("active_customer", JSON.stringify(action.payload));

      return {
        ...state,
        activeCustomer: action.payload,
      };
    case "DELETE_ACTIVE_CART":
      localStorage.removeItem("active_cart");

      return {
        ...state,
        activeCart: undefined,
      };
    case "DELETE_ACTIVE_TAX":
      localStorage.removeItem("active_tax");

      return {
        ...state,
        activeTax: undefined,
      };
    case "DELETE_ACTIVE_CUSTOMER":
      localStorage.removeItem("active_customer");

      return {
        ...state,
        activeCustomer: undefined,
      };
    case "REMOVE_ACTIVE_CUSTOMER":
      localStorage.removeItem("active_customer");

      return {
        ...state,
        activeCustomer: null,
      };
    case "SET_ACTIVE_CART":
      localStorage.setItem("active_cart", JSON.stringify(action.payload));

      return {
        ...state,
        activeCart: action.payload,
      };
    case "SET_ACTIVE_TAX":
      localStorage.setItem("active_tax", JSON.stringify(action.payload));

      return {
        ...state,
        activeTax: action.payload,
      };
    case "DELETE_ACTIVE_TAX":
      localStorage.removeItem("active_tax");

      return {
        ...state,
        activeTax: undefined,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "SET_CART_SHOW":
      return {
        ...state,
        cartShow: action.payload,
      };
    case "SET_SN_SHOW":
      return {
        ...state,
        snShow: action.payload,
      };
    case "SET_TAX_SHOW":
      return {
        ...state,
        taxShow: action.payload,
      };
    case "LOGOUT":
      localStorage.clear();

      return {
        ...state,
        isLogin: false,
      };
    default:
      throw new Error();
  }
};

export const AppContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={[state, dispatch]}>
      {props.children}
    </AppContext.Provider>
  );
};
