import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";

const TotalSummary = ({ activeCart, tax }) => {
  const [childDiscount, setChildDiscount] = useState(null);

  const getTotalDiscount = () => {
    let total = 0;
    activeCart.items.map((item) => {
      item.child_items &&
        item.child_items.map((child) => {
          total = total + child.discount;
        });
    });

    setChildDiscount(total);
  };

  useEffect(() => {
    getTotalDiscount();
  }, [activeCart]);

  return (
    <div className="row mt-3">
      <div className="col-md-4 mb-2">
        <div className="cart-sidebar-total-card-4">
          <div className="">DOSS Protection</div>
          <NumberFormat
            value={activeCart.protection_price}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            prefix={"IDR "}
            className="fw-bold"
          />
        </div>
      </div>
      {/* <div className="col-md-3 mb-2">
        <div className="cart-sidebar-total-card-3">
          <div className="">Pajak (11%)</div>
          <NumberFormat
            value={tax && tax.tax_ppn}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            prefix={"IDR "}
            className="fw-bold"
          />
        </div>
      </div> */}
      <div className="col-md-4 mb-2">
        <div className="cart-sidebar-total-card-2">
          <div className="">Discount (Item)</div>
          <NumberFormat
            value={activeCart.discount + childDiscount}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            prefix={"- IDR "}
            className="fw-bold"
          />

          {activeCart.coupon_discount !== 0 && (
            <>
              <div className="">Discount (Coupon)</div>
              <NumberFormat
                value={activeCart.coupon_discount}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                prefix={"- IDR "}
                className="fw-bold"
              />
            </>
          )}
        </div>
      </div>
      <div className="col-md-4 mb-2">
        <div className="cart-sidebar-total-card">
          <div className="">Total Barang</div>
          <NumberFormat
            value={activeCart.subtotal + childDiscount}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            prefix={"IDR "}
            className="fw-bold"
          />
        </div>
      </div>
    </div>
  );
};

export default TotalSummary;
