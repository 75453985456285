import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { AppContext } from "../configs/AppContext";
import NumberFormat from "react-number-format";
import { IoIosAddCircle } from "react-icons/io";
import { IoTrash } from "react-icons/io5";
import { BsSearch } from "react-icons/bs";

import { APISV, APIDOSS, APIALTO } from "../configs/API";

import Sidebar from "../components/Sidebar";
import TopNavbar from "../components/TopNavbar";
import CartPopup from "../components/CartPopup";
import CartSidebar from "../components/CartSidebar";
import TaxSidebar from "../components/TaxSidebar";
import SNSidebar from "../components/SNSidebar";

const Products = () => {
  const [state, dispatch] = useContext(AppContext);
  const [products, setProducts] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [searchType, setSearchType] = useState("keyword");
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;
  const [searchParams] = useSearchParams();
  const branch = JSON.parse(localStorage.getItem("branch"));
  const warehouse = JSON.parse(localStorage.getItem("warehouse"));
  const dbList = JSON.parse(localStorage.getItem("db_list"));
  const { id: branch_id } = branch;
  const { id: warehouse_id } = warehouse;

  const queryParams = Object.fromEntries([...searchParams]);

  const user = JSON.parse(localStorage.getItem("userInfo"));
  let activeCart = localStorage.getItem("active_cart");
  let activeCustomer = localStorage.getItem("active_customer");

  if (activeCart) {
    activeCart = JSON.parse(localStorage.getItem("active_cart"));
  }

  if (activeCustomer) {
    activeCustomer = JSON.parse(localStorage.getItem("active_customer"));
  }

  const filterKW = (kw) => {
    const filter = kw && kw.replace(/[#]/g, "").replace("&", "%26");
    return filter;
  };

  const addToCart = async (product_id) => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const payload = {
        product_id,
        quantity: 1,
        branch_id,
        warehouse_id,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      if (activeCart) {
        if (activeCustomer) {
          const resCreate = await APISV.post(
            `s-cart/${activeCart.id}/item`,
            payload,
            config
          );

          const body = { user_id: activeCustomer.id };
          const resPatch = await APISV.patch(
            `s-cart/${activeCart.id}`,
            body,
            config
          );

          if (resCreate.data.error == 0) {
            const resCart = await APISV.get(`cart/${activeCart.order_id}`);

            if (resCart.data.error == 0) {
              dispatch({
                type: "SET_ACTIVE_CART",
                payload: resCart.data.data,
              });
            } else {
              dispatch({
                type: "DELETE_ACTIVE_CART",
              });
              dispatch({
                type: "DELETE_ACTIVE_TAX",
              });
            }

            dispatch({
              type: "SET_TOAST",
              payload: {
                isOpen: true,
                message: "Berhasil ditambahkan ke keranjang",
              },
            });
          } else {
            alert(
              `Error ${resCreate.data.error} | ${resCreate.data.message} \nDB: ${dbList.alias} [${dbList.id}] \nBranch: ${branch.name} [${branch.id}] \nWarehouse: ${warehouse.name} [${warehouse.id}]`
            );
          }
        } else {
          navigate("/customers/search");
        }
      } else {
        if (activeCustomer) {
          const res = await APISV.post(`s-cart`);

          const body = { user_id: activeCustomer.id };
          await APISV.patch(`s-cart/${res.data.data.id}`, body, config);

          const resItem = await APISV.post(
            `s-cart/${res.data.data.id}/item`,
            payload,
            config
          );

          if (resItem.data.error == 0) {
            dispatch({
              type: "SET_TOAST",
              payload: {
                isOpen: true,
                message: "Berhasil ditambahkan ke keranjang",
              },
            });
          } else {
            alert(
              `Error ${resItem.data.error} | ${resItem.data.message} \nDB: ${dbList.alias} [${dbList.id}] \nBranch: ${branch.name} [${branch.id}] \nWarehouse: ${warehouse.name} [${warehouse.id}]`
            );
          }

          const resCart = await APISV.get(`cart/${res.data.data.order_id}`);
          if (resCart.data.error == 0) {
            dispatch({
              type: "SET_ACTIVE_CART",
              payload: resCart.data.data,
            });
          } else {
            dispatch({
              type: "DELETE_ACTIVE_CART",
            });
            dispatch({
              type: "DELETE_ACTIVE_TAX",
            });
          }
        } else {
          navigate("/customers/search");
        }
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      if (error.response.data) {
        alert(error.response.data.message);
      } else {
        alert(error);
      }
    }
  };

  const removeFromCart = async (product_id) => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const isCart =
        activeCart.items &&
        activeCart.items.find((item) => item.product_id === product_id);

      const resDel = await APISV.delete(
        `s-cart/${activeCart.id}/item/${isCart.id}`,
        null,
        config
      );

      if (resDel.data.error == 0) {
        const resCart = await APISV.get(`cart/${activeCart.order_id}`);
        if (resCart.data.error == 0) {
          dispatch({
            type: "SET_ACTIVE_CART",
            payload: resCart.data.data,
          });
        } else {
          dispatch({
            type: "DELETE_ACTIVE_CART",
          });
          dispatch({
            type: "DELETE_ACTIVE_TAX",
          });
        }
      } else {
        alert(`Error ${resDel.data.error} | ${resDel.data.message}`);
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });

      dispatch({
        type: "SET_TOAST",
        payload: {
          isOpen: true,
          message: "Berhasil dihapus dari keranjang",
        },
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      alert(error.response.data.message);
    }
  };

  const getProducts = async () => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      let res;

      if (searchType === "keyword") {
        // res = await APIDOSS.get(
        //   `product?per_page=15&${
        //     queryParams.keyword && `q=${filterKW(queryParams.keyword)}`
        //   }`
        // );

        res = await APIALTO.get(
          `search?query=${
            queryParams.keyword === "" ? "-" : filterKW(queryParams.keyword)
          }&skip=${0}&limit=${15}&filter={"term":{"type":[1]}}&token=${
            process.env.REACT_APP_ALTOTOKEN
          }&filter={"term":{"product_status":[1]}}`
        );
      } else if (searchType === "productId" && queryParams.productId) {
        res = await APIDOSS.get(`product/${filterKW(queryParams.productId)}`);
      }

      if (searchType === "productId" && res.data.error == 0) {
        setProducts([res.data.data]);
      } else {
        setProducts(res.data.products);
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      alert(error.response.data.message);
    }
  };

  const isInCart = (itemId) => {
    if (activeCart) {
      const isCart =
        activeCart.items &&
        activeCart.items.find((item) => item.product_id === itemId);

      if (isCart) {
        return true;
      } else {
        return false;
      }
    } else {
      return null;
    }
  };

  const handleSearch = (e) => {
    e && e.preventDefault();
    if (searchType === "keyword") {
      navigate({
        pathname: "/products",
        search: `?keyword=${filterKW(keyword)}`,
      });
    } else if (searchType === "productId") {
      navigate({
        pathname: "/products",
        search: `?productId=${filterKW(keyword)}`,
      });
    }
  };

  useEffect(() => {
    if (queryParams.keyword || queryParams.productId) {
      getProducts();
    }

    if (queryParams.keyword) {
      setKeyword(queryParams.keyword);
    }
    if (queryParams.productId) {
      setKeyword(queryParams.productId);
    }
  }, [search]);

  return (
    <div className="main-container pb-5">
      <Sidebar />
      <CartPopup />
      <CartSidebar />
      <TaxSidebar />
      <SNSidebar />

      <div
        className="w-100"
        style={{
          paddingLeft: state.collapse ? "80px" : "270px",
          transition: "width, left, right, 0.3s",
        }}
      >
        <TopNavbar title="Product" />

        <div className="px-4">
          <form onSubmit={handleSearch}>
            <div className="top-navbar-search-by mb-2">
              <span className="me-2">Cari Berdasarkan: </span>

              <input
                type="radio"
                id="keyword"
                name="keyword"
                value="keyword"
                checked={searchType === "keyword"}
                onChange={(e) => setSearchType(e.target.value)}
                className="me-1"
              />
              <label htmlFor="keyword" className="cursor-pointer me-4">
                Keyword
              </label>

              <input
                type="radio"
                id="productId"
                name="productId"
                value="productId"
                checked={searchType === "productId"}
                onChange={(e) => setSearchType(e.target.value)}
                className="me-1"
              />
              <label htmlFor="productId" className="cursor-pointer">
                Product ID
              </label>
            </div>
            <div className="d-flex">
              <div className="top-navbar-input mb-3 me-2">
                <BsSearch className="top-navbar-icon" />
                <input
                  type="text"
                  placeholder="Cari product.."
                  className="form-control"
                  style={{ height: "48px", width: "500px" }}
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />

                <button
                  type="submit"
                  className="btn btn-primary top-navbar-btn"
                  style={{ width: "100px" }}
                >
                  Cari
                </button>
              </div>
            </div>
          </form>

          {/* {search !== "" && (
            <>
              <h1 className="header-h1">Hasil Pencarian "{search}"</h1>
              <div>Total 281 Item</div>
            </>
          )} */}

          <div className="product-grid-container mt-4">
            {products &&
              products.map((product) => (
                <div
                  key={product.id || product.productId}
                  className="product-card"
                >
                  <img
                    src={product.featured_image || product.image_link}
                    alt={product.name}
                    title={product.name}
                    onClick={() =>
                      window.open(
                        `https://doss.co.id/product/${product.slug}`,
                        "_blank"
                      )
                    }
                  />

                  <div className="product-content">
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <div className="product-id-txt">
                        #{product.id || product.item_id}
                      </div>
                      {product.stock && (
                        <div className="product-stock">
                          Stock: {product.stock}
                        </div>
                      )}

                      {product.product_label === "Out of Stock" && (
                        <div className="product-stock-gray">
                          {product.product_label}
                        </div>
                      )}
                    </div>
                    <div
                      title={product.name}
                      className="product-name-txt mt-3"
                      onClick={() =>
                        window.open(
                          `https://doss.co.id/product/${product.slug}`,
                          "_blank"
                        )
                      }
                    >
                      {product.name}
                    </div>

                    <NumberFormat
                      value={
                        product.discount_price
                          ? product.discount_price
                          : product.price
                      }
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix={"IDR "}
                      className="product-price-txt mt-2"
                    />
                    {/* <div className="product-add-to-cart">Add to Cart</div> */}
                  </div>

                  {isInCart(product.id) == true && (
                    <IoTrash
                      size={35}
                      className="product-add-icon"
                      onClick={() => removeFromCart(product.id)}
                    />
                  )}

                  {isInCart(product.item_id) == true && (
                    <IoTrash
                      size={35}
                      className="product-add-icon"
                      onClick={() => removeFromCart(product.item_id)}
                    />
                  )}

                  {(isInCart(product.id) == false ||
                    isInCart(product.id) == null) &&
                    product.stock > 0 && (
                      <IoIosAddCircle
                        size={35}
                        className="product-add-icon"
                        onClick={() => addToCart(product.id)}
                      />
                    )}

                  {product.item_id && (
                    <>
                      {(isInCart(product.item_id) == false ||
                        isInCart(product.item_id) == null) &&
                        product.product_label !== "Out of Stock" &&
                        product.product_label !== "Coming Soon" &&
                        product.product_label !== "Pre Order" &&
                        product.product_label !== "Discontinue" && (
                          <IoIosAddCircle
                            size={35}
                            className="product-add-icon"
                            onClick={() => addToCart(product.item_id)}
                          />
                        )}
                    </>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
