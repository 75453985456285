import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../configs/AppContext";
import Skeleton from "react-loading-skeleton";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { IoIosCheckmarkCircle } from "react-icons/io";

import Officer from "../assets/images/doss-officer-webp.webp";
import NoResult from "../assets/images/no-item-webp.webp";
import NoAvatar from "../assets/images/no-avatar-webp.webp";

import Sidebar from "../components/Sidebar";
import TopNavbar from "../components/TopNavbar";

import { APISV } from "../configs/API";
import cleanObject from "../helpers/cleanObject";

const CustomerCreate = () => {
  const [state, dispatch] = useContext(AppContext);
  const navigate = useNavigate();
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    fullname: "",
  });
  const [dialCode, setDialCode] = useState("");
  const [phone, setPhone] = useState("");

  const { email, fullname } = formData;

  const filterPhone = (phone_number) => {
    const filter = phone_number.replace(/[+-\s\(\)]/g, "").replace(/^62/, "0");

    if (filter[0] === "0") {
      return filter.substring(1);
    } else {
      return filter;
    }
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const body = formData;
      body.phone = filterPhone(phone);
      body.dial_code = dialCode;
      const payload = body;
      const res = await APISV.post("account/create", payload);

      if (res.status == 200) {
        if (res.data.error == 422) {
          alert(JSON.stringify(res.data.errors));
        }
        if (res.data.error === 404) {
          alert(res.data.description);
        }
        if (res.data.error === 0) {
          setCustomer(res.data.data);
        }
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert(error);
    }
  };

  const handleChoose = async () => {
    try {
      setLoading(true);

      if (state.activeCart) {
        const res = await APISV.delete(`s-cart/${state.activeCart.id}`);

        if (res.data.error == 0) {
          dispatch({
            type: "DELETE_ACTIVE_CART",
          });
          dispatch({
            type: "DELETE_ACTIVE_TAX",
          });

          dispatch({
            type: "SET_CART_SHOW",
            payload: false,
          });
          dispatch({
            type: "SET_SN_SHOW",
            payload: false,
          });
          dispatch({
            type: "SET_TAX_SHOW",
            payload: false,
          });
        } else {
          alert(res.data.description);
        }
      }

      dispatch({
        type: "SET_ACTIVE_CUSTOMER",
        payload: customer,
      });

      navigate("/products");

      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error.response.data.message);
    }
  };

  const handleEdit = () => {
    dispatch({
      type: "SET_ACTIVE_CUSTOMER",
      payload: customer,
    });

    navigate("/customers/update");
  };

  return (
    <div className="main-container">
      <Sidebar />

      <div
        className="w-100"
        style={{
          paddingLeft: state.collapse ? "80px" : "270px",
          transition: "width, left, right, 0.3s",
        }}
      >
        <TopNavbar title="Customer" disableSearch={true} />

        <div className="d-flex justify-content-between px-4">
          <h1 className="header-h1">Tambah Customer Baru</h1>

          <button
            className="btn btn-primary customer-add-btn"
            onClick={() => navigate("/customers/search")}
          >
            Cari Customer
          </button>
        </div>

        <div className="row mt-4">
          <div className="col-md-6">
            <form onSubmit={handleCreate}>
              <div className="form-group px-4 mt-2">
                <label>Alamat Email</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Masukan alamat email customer"
                  name="email"
                  value={email || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />

                <label className="mt-3">Nomor Handphone (Whatsapp)</label>
                <PhoneInput
                  country={"id"}
                  placeholder=""
                  value={phone}
                  onChange={(e, data) => {
                    setPhone(e);
                    setDialCode(data.dialCode);
                  }}
                  countryCodeEditable={false}
                  inputClass="form-control w-100 h-48"
                />
                {/* <input
                  type="tel"
                  className="form-control"
                  placeholder="Masukan nomor handphone customer"
                  name="phone"
                  value={phone || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                  }
                /> */}

                <label className="mt-3">Nama Lengkap</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Masukan nama lengkap customer"
                  name="fullname"
                  value={fullname || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, fullname: e.target.value })
                  }
                />

                <button
                  type="submit"
                  className="btn btn-primary w-100 mt-4"
                  disabled={
                    formData.email === "" ||
                    formData.phone === "" ||
                    formData.fullname === ""
                  }
                >
                  Tambah Customer
                </button>
              </div>
            </form>
          </div>

          <div className="col-md-6">
            {!customer && !loading && (
              <div className="customer-search-card">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={Officer}
                    alt="logo account"
                    className="officer-search-img"
                  />

                  <div className="officer-search-name mt-4">
                    Tambahkan Data Customer Baru
                  </div>
                  <div className="customer-search-info mt-2">
                    Hasil Customer Baru Akan Muncul Disini
                  </div>
                </div>
              </div>
            )}

            {loading && (
              <div className="customer-search-card">
                <div className="d-flex flex-column align-items-center">
                  <div className="customer-search-img">
                    <Skeleton circle style={{ height: "100%" }} />
                  </div>

                  <div className="customer-search-name w-100 mt-2">
                    <Skeleton />
                  </div>
                  <div className="customer-search-info w-100">
                    <Skeleton />
                  </div>
                  <div className="w-100">
                    <Skeleton />
                  </div>
                </div>

                <div className="w-100">
                  <Skeleton style={{ height: "30px" }} />
                  <Skeleton style={{ height: "30px" }} />
                </div>
              </div>
            )}

            {customer && !loading && (
              <div className="customer-search-card">
                <div className="d-flex flex-column align-items-center">
                  <div className="customer-search-img">
                    <img src={customer.avatar || Officer} alt="logo account" />
                    {/* <Skeleton circle style={{ height: "100%" }} /> */}
                  </div>

                  <div className="customer-search-name w-100 mt-2">
                    {customer.fullname}
                  </div>
                  <div className="customer-search-info">{customer.email}</div>
                  <div className="customer-search-info d-flex align-items-center">
                    {customer.phone}{" "}
                    {customer.unverified_phone == null && (
                      <span className="ms-1">
                        <IoIosCheckmarkCircle color="#3ec690" size={20} />
                      </span>
                    )}
                  </div>
                </div>

                <div>
                  <button
                    className="btn btn-primary w-100"
                    onClick={handleChoose}
                  >
                    Pilih Customer
                  </button>
                  <button
                    className="btn btn-secondary w-100 mt-2"
                    onClick={handleEdit}
                  >
                    Ubah Profile
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerCreate;
