import React, { useState, useContext } from "react";
import { Modal, Spinner, Button } from "react-bootstrap";

import { AppContext } from "../configs/AppContext";
import { APISV } from "../configs/API";

const ModalConfirmDelete = ({ show, handleClose, activeCart }) => {
  const [state, dispatch] = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const deleteCart = async () => {
    try {
      setLoading(true);
      const res = await APISV.delete(`s-cart/${activeCart.id}`);

      if (res.data.error == 0) {
        dispatch({
          type: "DELETE_ACTIVE_CART",
        });
        dispatch({
          type: "DELETE_ACTIVE_TAX",
        });

        dispatch({
          type: "SET_CART_SHOW",
          payload: false,
        });
        dispatch({
          type: "SET_SN_SHOW",
          payload: false,
        });
        dispatch({
          type: "SET_TAX_SHOW",
          payload: false,
        });

        handleClose();
      } else {
        alert(res.data.description);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error.response.data.message);
    }
  };

  return (
    <Modal size="sm" show={show} onHide={handleClose}>
      <Modal.Body>
        <div className="d-flex justify-content-between mb-3">
          <div className="modal-coupon-header">Hapus Keranjang?</div>
        </div>

        <div>
          Cart No : <span className="modal-delete-txt">{activeCart.id}</span>
        </div>
        <div>
          Order ID :{" "}
          <span className="modal-delete-txt">{activeCart.order_id}</span>
        </div>

        <div className="d-flex justify-content-between mt-3">
          <Button
            variant="outline-secondary w-100 me-2"
            disabled={loading}
            onClick={handleClose}
          >
            Batalkan
          </Button>

          <Button
            variant="danger w-100"
            disabled={loading}
            onClick={() => deleteCart()}
          >
            {loading && (
              <Spinner
                animation="border"
                style={{ color: "#ffff", width: "15px", height: "15px" }}
              />
            )}
            {!loading && "Ya, Hapus"}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalConfirmDelete;
