import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../configs/AppContext";
import Skeleton from "react-loading-skeleton";

import { IoIosCheckmarkCircle } from "react-icons/io";

import Officer from "../assets/images/doss-officer-webp.webp";
import NoResult from "../assets/images/no-item-webp.webp";
import NoAvatar from "../assets/images/no-avatar-webp.webp";

import Sidebar from "../components/Sidebar";
import TopNavbar from "../components/TopNavbar";

import { APISV } from "../configs/API";
import cleanObject from "../helpers/cleanObject";

const CustomerUpdate = () => {
  const user = JSON.parse(localStorage.getItem("active_customer"));
  const [state, dispatch] = useContext(AppContext);
  const navigate = useNavigate();
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    fullname: "",
  });

  // const { id } = user;

  const { email, phone, fullname } = formData;

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const payload = cleanObject(formData);
      const res = await APISV.patch(`account/update/${user.id}`, payload);

      if (res.status == 200) {
        if (res.data.error == 422) {
          alert(JSON.stringify(res.data.errors));
        }
        if (res.data.error === 404) {
          alert(res.data.description);
        }
        if (res.data.error === 0) {
          setCustomer(res.data.data);
        }
      }

      dispatch({
        type: "SET_ACTIVE_CUSTOMER",
        payload: res.data.data,
      });

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert(error);
    }
  };

  useEffect(() => {
    console.log("state.activeCustomer", state.activeCustomer);
    if (state.activeCustomer) {
      setFormData({
        email: state.activeCustomer.email,
        phone: state.activeCustomer.phone,
        fullname: state.activeCustomer.fullname,
      });
    } else {
      navigate("/");
    }
  }, [state.activeCustomer]);

  return (
    <div className="main-container">
      <Sidebar />

      <div
        className="w-100"
        style={{
          paddingLeft: state.collapse ? "80px" : "270px",
          transition: "width, left, right, 0.3s",
        }}
      >
        <TopNavbar title="Customer" disableSearch={true} />

        <div className="d-flex justify-content-between px-4">
          <h1 className="header-h1">Update Customer</h1>

          <button
            className="btn btn-primary customer-add-btn"
            onClick={() => navigate("/customers/search")}
          >
            Search Customer
          </button>
        </div>

        <div className="row mt-4">
          <div className="col-md-6">
            <form onSubmit={handleUpdate}>
              <div className="form-group px-4 mt-2">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Input email"
                  name="email"
                  value={email || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />

                <label className="mt-3">Phone Number</label>
                <input
                  type="tel"
                  className="form-control"
                  placeholder="Input phone"
                  name="phone"
                  value={phone || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                  }
                />

                <label className="mt-3">Full Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Input fullname"
                  name="fullname"
                  value={fullname || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, fullname: e.target.value })
                  }
                />

                <button
                  type="submit"
                  className="btn btn-primary w-100 mt-4"
                  disabled={!email || !phone || !fullname}
                >
                  Ubah Data Customer
                </button>
              </div>
            </form>
          </div>

          <div className="col-md-6">
            {loading && (
              <div className="customer-search-card">
                <div className="d-flex flex-column align-items-center">
                  <div className="customer-search-img">
                    <Skeleton circle style={{ height: "100%" }} />
                  </div>

                  <div className="customer-search-name w-100 mt-2">
                    <Skeleton />
                  </div>
                  <div className="customer-search-info w-100">
                    <Skeleton />
                  </div>
                  <div className="w-100">
                    <Skeleton />
                  </div>
                </div>

                <div className="w-100">
                  <Skeleton style={{ height: "30px" }} />
                  <Skeleton style={{ height: "30px" }} />
                </div>
              </div>
            )}

            {state.activeCustomer && !loading && (
              <div className="customer-search-card">
                <div className="d-flex flex-column align-items-center">
                  <div className="customer-search-img">
                    <img
                      src={state.activeCustomer.avatar || Officer}
                      alt="logo account"
                    />
                  </div>

                  <div className="customer-search-name w-100 mt-2">
                    {state.activeCustomer.fullname}
                  </div>
                  <div className="customer-search-info">
                    {state.activeCustomer.email}
                  </div>
                  <div className="customer-search-info d-flex align-items-center">
                    {state.activeCustomer.phone}{" "}
                    {state.activeCustomer.unverified_phone == null && (
                      <span className="ms-1">
                        <IoIosCheckmarkCircle color="#3ec690" size={20} />
                      </span>
                    )}
                  </div>
                </div>

                {/* <div>
                  <button
                    className="btn btn-primary w-100"
                    onClick={handleChoose}
                  >
                    Choose Customer
                  </button>
                  <button className="btn btn-secondary w-100 mt-2">
                    Change Profile
                  </button>
                </div> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerUpdate;
