import React, { useState, useEffect, useContext } from "react";
import { Spinner } from "react-bootstrap";
import { IoMdArrowDropdown } from "react-icons/io";
import { useNavigate } from "react-router-dom";

import { APIO2O } from "../configs/API";
import { AppContext } from "../configs/AppContext";

const Warehouse = () => {
  const [state, dispatch] = useContext(AppContext);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    database: "",
    branch: "",
    warehouse: "",
  });
  const [loading, setLoading] = useState(false);
  const [databaseList, setDatabaseList] = useState(null);
  const [branchList, setBranchList] = useState(null);
  const [warehouseList, setWarehouseList] = useState(null);
  const { database, branch, warehouse } = formData;

  const getListDB = async () => {
    try {
      const res = await APIO2O.get("aol/db/list");
      if (res.data.error == 0) {
        setDatabaseList(res.data.data);
      } else {
        alert(res.data.message);
        dispatch({
          type: "SET_LOADING",
          payload: false,
        });
      }
    } catch (error) {
      console.log("error", error);
      if (error.response.data) {
        alert(error.response.data.message);
      } else {
        alert(error);
      }
    }
  };

  const openDB = async (db_id) => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      if (db_id) {
        const res = await APIO2O.get(`aol/db/open/${db_id}`);
        if (res.data.error == 0) {
          const filtered = databaseList.filter((data) => data.id == db_id);
          localStorage.setItem("db_list", JSON.stringify(filtered[0]));
          localStorage.setItem("db_open", JSON.stringify(res.data.data));
          getBranchList();
        } else {
          alert(res.data.message);
          dispatch({
            type: "SET_LOADING",
            payload: false,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      if (error.response.data) {
        alert(error.response.data.message);
      } else {
        alert(error);
      }
    }
  };

  const getBranchList = async () => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const res = await APIO2O.get("aol/branch/list");
      if (res.data.error == 0) {
        setBranchList(res.data.data);
      } else {
        alert(res.data.message);
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      if (error.response.data) {
        alert(error.response.data.message);
      } else {
        alert(error);
      }
    }
  };

  const getWarehouseList = async (branch_id) => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const res = await APIO2O.get(`aol/warehouse/list?branch_id=${branch_id}`);
      if (res.data.error == 0) {
        setWarehouseList(res.data.data);
      } else {
        alert(res.data.message);
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      if (error.response.data) {
        alert(error.response.data.message);
      } else {
        alert(error);
      }
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      const findBranch = branchList.find((item) => item.id == branch);
      const findWarehouse = warehouseList.find((item) => item.id == warehouse);

      localStorage.setItem("branch", JSON.stringify(findBranch));
      localStorage.setItem("warehouse", JSON.stringify(findWarehouse));

      navigate("/");

      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error);
    }
  };

  useEffect(() => {
    getListDB();
    // getBranchList();
  }, []);

  return (
    <div className="login-container">
      <div className="card-default login-card">
        <form onSubmit={handleSubmit}>
          <h2 className="fw-bold mb-3">Pilih Database</h2>

          <div className="position-relative">
            <select
              className="form-control"
              name="database"
              value={database}
              onChange={(e) => {
                setFormData({ ...formData, [e.target.name]: e.target.value });
                openDB(e.target.value);
              }}
              disabled={loading || !databaseList}
            >
              <option value="">Pilih salah satu</option>
              {databaseList &&
                databaseList.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.alias}
                  </option>
                ))}
            </select>

            <IoMdArrowDropdown
              className="arrow-select"
              size={25}
              color="gray"
            />
          </div>

          <h2 className="fw-bold mt-4 mb-3">Pilih Cabang</h2>

          <div className="position-relative">
            <select
              className="form-control"
              name="branch"
              value={branch}
              onChange={(e) => {
                setFormData({ ...formData, [e.target.name]: e.target.value });
                getWarehouseList(e.target.value);
              }}
              disabled={loading || !branchList || !database}
            >
              <option value="">Pilih salah satu</option>
              {branchList &&
                branchList.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>

            <IoMdArrowDropdown
              className="arrow-select"
              size={25}
              color="gray"
            />
          </div>

          <h2 className="fw-bold mt-4 mb-3">Pilih Warehouse</h2>

          <div className="position-relative">
            <select
              className="form-control"
              name="warehouse"
              value={warehouse}
              onChange={(e) =>
                setFormData({ ...formData, [e.target.name]: e.target.value })
              }
              disabled={loading || !warehouseList}
            >
              <option value="">Pilih salah satu</option>
              {warehouseList &&
                warehouseList.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>

            <IoMdArrowDropdown
              className="arrow-select"
              size={25}
              color="gray"
            />
          </div>

          <button
            type="submit"
            className="btn btn-primary w-100 fw-bold mt-5"
            style={{ minHeight: "50px" }}
            disabled={!branch || !warehouse || loading}
          >
            {loading && (
              <Spinner
                animation="border"
                style={{ color: "#ffff", width: "15px", height: "15px" }}
              />
            )}{" "}
            Lanjutkan
          </button>
        </form>
      </div>
    </div>
  );
};

export default Warehouse;
